import React from 'react';

const SettingsConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/settings/users',
      component: React.lazy(() => import('./users/Users'))
    },
    {
      path: '/settings/user/:userId',
      component: React.lazy(() => import('./user/User'))
    },
    {
      path: '/settings/userprofile',
      component: React.lazy(() => import('./userprofile/Userprofile'))
    },
    {
      path: '/settings/sales-target',
      component: React.lazy(() => import('./sales-target/SalesTarget'))
    }
  ]
};

export default SettingsConfig;
