import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  headernav: {
    background: '#FFF',
    borderBottom: '1px solid #ccc',
    zIndex: 50,
},
  loginIcons: {
    width: '26px',
    '@media screen and (max-width: 959px)': {
      width: '26px',
    },
  },
  avatar: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    fontSize: '1.5em',
    boxSizing: 'content-box',
    left: '10%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    '&.initials': {
      width: 72,
      height: 72,
      padding: 5
    },
    '& > img': {
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    '@media screen and (max-width: 959px)': {
      width: 25,
      height: 25,
      marginTop: '10px',
      marginBottom: '10px',
      marginRight: '-14px',
      fontSize: '1em',
      '& > img': {
        objectFit: 'cover'
      }
    },
  },
  loginRow: {
    width: '7.5px',
    height: '7.5px',
    marginLeft: '-5px',
    '@media screen and (max-width: 959px)': {
      width: '7.5px',
      height: '7.5px',
    },
  },
  loginRowB: {
    width: '7.5px',
    height: '7.5px',
    '@media screen and (max-width: 959px)': {
      width: '7.5px',
      height: '7.5px',
    },
  },
  loginRowUp: {
    width: '7.5px',
    height: '7.5px',
    '@media screen and (max-width: 959px)': {
      width: '7.5px',
      height: '7.5px',
    },
  },
  hiddenNav: {
    maxWidth: '186px',
    width: '186px',
    // marginTop: '16px',
    position: 'fixed', // Lo fijamos en la pantalla
    top: '58px', // Alineado en la parte superior
    left: '37px', // Pegado al borde izquierdo
    paddingLeft: '36px',
    paddingRight: '36px',
    paddingBottom: '20px',
    paddingTop: '20px',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginLeft: '-36px',
    '@media screen and (max-width: 1279px)': {
      // marginTop: '17px',
      // marginLeft: '-36px',
    },
    '@media screen and (max-width: 959px)': {
      top: '44px', // Alineado en la parte superior
    left: '36px',
    },
  },
  hiddenNav2: {
    position: 'absolute', // Asegura que el dropdown pueda colocarse relativo a su contenedor más cercano
    top: '58px', // Si necesitas especificar una posición vertical
    right: '0', // Mantiene el dropdown pegado al borde derecho
    padding: '20px 60px', // Espaciado interno
    borderRight: '1px solid #ccc', // Si quieres mantener los bordes
    borderBottom: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
    '@media screen and (max-width: 1600px)': {
      top: '50px',
    },
    '@media screen and (max-width: 1280px)': {
      top: '47px',
    },
    '@media screen and (max-width: 959px)': {
      top: '45px',
      padding: '15px 40px',
    },
  },
  hiddenNav3: {
    position: 'absolute', // Asegura que el dropdown pueda colocarse relativo a su contenedor más cercano
    top: '57px', // Si necesitas especificar una posición vertical
    right: '0', // Mantiene el dropdown pegado al borde derecho
    padding: '20px 60px', // Espaciado interno
    borderRight: '1px solid #ccc', // Si quieres mantener los bordes
    borderLeft: '1px solid #ccc', // Si quieres mantener los bordes
    borderBottom: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
    '@media screen and (max-width: 1600px)': {
      top: '57px',
    },
    '@media screen and (max-width: 1280px)': {
      top: '47px',
    },
    '@media screen and (max-width: 959px)': {
      top: '44px',
      padding: '15px 40px',
    },
  },
  mapNav: {
    gap: '6.4px',
  },
  subMenu: {
    paddingLeft: '15px',
  },
  alink: {
    fontFamily: 'Gotham',
    fontWeight: '400',
    color: 'black !important',
    lineHeight: '24px',
    fontSize: '14px',
    '&:hover': {
      color: '#ee1c25 !important',
      textDecoration: 'none !important',
    },
  },
  alink1: {
    fontFamily: 'Gotham',
    fontWeight: '400',
    color: 'black !important',
    lineHeight: '26px',
    fontSize: '12px',
    '&:hover': {
      textDecoration: 'none !important',
      color: '#ee1c25 !important',
    },
  },
  encompassText: {
    fontSize: '10px', 
    fontWeight: 400,
    fontFamily: 'Gotham',
    color: 'black !important',
    lineHeight: '36px',
  },
  logo: {
    width: '150px',
    marginLeft: '174px',
    '@media screen and (max-width: 959px)': {
      width: '116px',
      marginLeft: '0px',
      position: 'absolute',
      top: '0px', // Ajusta la posición vertical del logo
      left: '50%',
      transform: 'translateX(-50%)', // Centra el logo horizontalmente
    },
  },
}));

const getBaseUrlFromHostname = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost')) return "http://localhost:3006";
  if (hostname.includes('wedoweb')) return "https://icentral.wedoweb.io";
  if (hostname.includes('iloanrate')) return "https://icentral.iloanrate.com";
  return "http://localhost:3006";
};

const links = [
  { name: 'Closing Board', customUrl: `${getBaseUrlFromHostname()}/closing-board/calendar` },
  { name: 'Sales Hub', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/sales-hub' },
  { name: 'Marketing Hub', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/marketing' },
  { name: 'Help Center', customUrl: ' https://sites.google.com/iloanrate.com/icentral/home/help-center' },
  { name: 'Directory', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/marketing' },
];

const links2 = [
  { name: 'Admin Console', customUrl: `${getBaseUrlFromHostname()}/closing-board/calendar` },
  { name: 'Sign Out', customUrl: '/logout' },
  { name: 'Encompass:', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/marketing' },
];

const NavbarHome = () => {
  const classes = useStyles();
  const [isLeftOpen, setIsLeftOpen] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);
  const [isDirectoryOpen, setIsDirectoryOpen] = useState(false);
  const [isHamburgerMenu, setIsHamburgerMenu] = useState(true);

  
  const user = useSelector(({ auth }) => auth.user);
  const userName = user.data.displayName;
  const { avatar } = user.data;

  console.log('avatar', user.data)

    const userRole = useSelector(state => state.auth.user.role);
    const isAdmin = userRole.includes('ADMIN');
    const encompassId = user.data.encompassId
   

  const getInitials = userName =>
    userName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  const getAvatarImg = avatar => {
    return <img className="max-w-none w-auto h-full" src={avatar} alt="avatar" />;
  };
  const avatarContent = avatar ? getAvatarImg(avatar) : getInitials(userName);

  const toggleLeftDropdown = () => {
    setIsLeftOpen((prev) => !prev);
    setIsHamburgerMenu(!isHamburgerMenu); // Alternar entre hamburguesa y cruz
    setIsRightOpen(false);
    setIsDirectoryOpen(false);
  };

  const toggleRightDropdown = () => {
    setIsRightOpen((prev) => !prev);
    setIsLeftOpen(false); // Cierra el dropdown izquierdo
    if (!isHamburgerMenu) {
      setIsHamburgerMenu(!isHamburgerMenu);
      
    }
  };

  const toggleDirectoryMenu = () => {
    setIsDirectoryOpen((prev) => !prev); // Controla el submenú
  };

  return (
    <header className={clsx(classes.headernav, 'lg:pt-3 lg:pb-4 w-full')}>
      <div className="mx-20 md:mx-36">
        <div className="flex container items-center justify-between">
          {/* Dropdown izquierda */}
          <div>
          <div onClick={toggleLeftDropdown} className="cursor-pointer outline-none">
            <img
              className={clsx(classes.loginIcons)}
              src={isHamburgerMenu ? "assets/images/icons/Hamburger_menu.svg" : "assets/images/icons/close-x-svgrepo-com.svg"}
              alt={isHamburgerMenu ? "Hamburger menu" : "Close menu"}
              style={{
                transition: 'transform 0.4s ease, opacity 0.4s ease-in-out',
                transform: isHamburgerMenu ? 'rotate(0deg)' : 'rotate(90deg)',
                opacity: isHamburgerMenu ? 1 : 0.8,  // Ejemplo de transición más fluida
              }}
            />
          </div>
          <div
            className={clsx(
              classes.hiddenNav,
              `bg-white fixed z-20 transition-all duration-500 ease-in-out`,
              isLeftOpen
                ? 'opacity-100 translate-x-0 visible'
                : 'opacity-0 -translate-x-10 invisible'
            )}
          >
              <div className={clsx(classes.mapNav, 'flex flex-col')}>
                {links.map((link, index) => {
                  if (link.name === 'Directory') {
                    return (
                      <React.Fragment key={index}>
                        <div
                          onClick={toggleDirectoryMenu}
                          className={`flex items-center cursor-pointer ${
                            isDirectoryOpen ? 'gap-10' : 'gap-16'
                          }`}
                        >
                          <div className={clsx(classes.alink)} href={link.customUrl}>
                            Directory
                          </div>
                          {isDirectoryOpen ? <img
                            className={clsx(
                              classes.loginRowUp,
                            )}
                            src="assets/images/icons/up-arrow.png"
                            alt="Toggle sub-menu"
                          /> : <img
                            className={clsx(
                              classes.loginRow,
                              'transition-transform duration-300'
                            )}
                            src="assets/images/icons/down-arrow.png"
                            alt="Toggle sub-menu"
                          />}
                        </div>
                        {isDirectoryOpen && (
                          <div className={clsx(classes.subMenu, 'mt-2 flex flex-col gap-2')}>
                            <a href={`${getBaseUrlFromHostname()}/data-directory/lead-sources`} className={clsx(classes.alink1)}>
                              Lead Sources
                            </a>
                            <a href={`${getBaseUrlFromHostname()}/data-directory/title-companies`} className={clsx(classes.alink1)}>
                              Title Companies
                            </a>
                            <a href={`${getBaseUrlFromHostname()}/data-directory/lenders`} className={clsx(classes.alink1)}>
                              Lenders
                            </a>
                            {isAdmin && (
                              <a href={`${getBaseUrlFromHostname()}/settings/users`} className={clsx(classes.alink1)}>
                                Users
                              </a>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                  return (
                    <a
                      key={index}
                      className={clsx(classes.alink)}
                      target="_self"
                      href={link.customUrl}
                    >
                      {link.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Logo central */}
          <div>
            <Link to="/menu/home-page">
              <img
                className={clsx(classes.logo)}
                src="assets/images/logos/icentral-logo.png"
                alt="logo"
              />
            </Link>
          </div>

          {/* Dropdown derecha */}
          <div className="flex gap-20 md:gap-16">
            <div className="flex gap-10 items-center">
              <a href='https://sites.google.com/iloanrate.com/icentral/faq'>
                <img
                  className="w-20 lg:w-24"
                  src="assets/images/icons/FAQ-icon.png"
                  alt="FAQ"
                />
              </a>
              <img
                className="w-20 lg:w-24"
                src="assets/images/icons/notification.png"
                alt="Notifications"
              />
            </div>
            <div
              onClick={toggleRightDropdown}
              className="flex gap-12 items-center cursor-pointer"
            >
              <Avatar className={clsx(classes.avatar, 'avatar')} alt="">
                {avatarContent}
              </Avatar>
              <div className='hidden md:block'>
                <Typography className="username text-14 whitespace-no-wrap text-black font-800">
                  {user.data.displayName}
                </Typography>
                <Typography className="email text-12 whitespace-no-wrap text-black">
                  {user.data.email}
                </Typography>
                </div>
                  {isRightOpen ? (
                    <img className={clsx(classes.loginRowUp, 'hidden sm:block')} src="assets/images/icons/up-arrow.png" alt="up arrow" />
                  ) : (
                    <img className={clsx(classes.loginRowB, 'hidden sm:block')} src="assets/images/icons/down-arrow.png" alt="down arrow" />
                  )}
            </div>
            <div
                className={clsx(
                  encompassId ? classes.hiddenNav2 : classes.hiddenNav3,
                  `bg-white fixed z-20 transition-all duration-500 ease-in-out`,
                  isRightOpen
                    ? 'opacity-100 translate-x-0 visible'
                    : 'opacity-0 translate-x-10 invisible'
                )}
              >
              <div className={clsx(classes.mapNav, 'flex flex-col')}>
                <hr className="border-t border-black" />
                {links2.map((link, index) => {
                  if (link.name === 'Admin Console' && !isAdmin) {
                    return null; // Oculta "Admin Console" si no es admin
                  }

                  if (link.name === 'Encompass:') {
                    // Reemplaza dinámicamente el texto con Conectado o Desconectado
                    return (
                      <React.Fragment key={index}>
                        <div className={clsx(classes.encompassText)}>
                          {`Encompass: ${encompassId ? 'Connected' : 'Disconnected'}`}
                        </div>
                      </React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={index}>
                      <a
                        className={clsx(classes.alink)}
                        target="_self"
                        href={link.customUrl}
                      >
                        {link.name}
                        {/* Si el enlace es 'Sign Out', agrega el ícono */}
                        {link.name === 'Sign Out' && (
                          <img
                            className={clsx(classes.loginRow, 'ml-20 inline w-20 h-20')}
                            src="assets/images/icons/logout.svg"
                            alt="Logout Icon"
                          />
                        )}
                      </a>
                      {/* Solo si el enlace es 'Sign Out', agregamos un <hr> */}
                      {link.name === 'Sign Out' && <hr className="border-t border-black my-2" />}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarHome;
