import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  headernav: {
    background: '#FFF',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 50,
    position: 'relative', // Asegura que los hijos posicionados se basen en este contenedor
    '@media screen and (max-width: 959px)': {
      paddingTop: '60px', // Ajusta este valor según la altura del logo
    },
  },
  loginIcons: {
    width: '30px',
    '@media screen and (max-width: 959px)': {
      width: '30px',
    },
  },
  avatar: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    fontSize: '1.5em',
    boxSizing: 'content-box',
    left: '10%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    '&.initials': {
      width: 72,
      height: 72,
      padding: 5
    },
    '& > img': {
      borderRadius: '50%'
    }
  },
  loginRow: {
    width: '10px',
    height: '10px',
    '@media screen and (max-width: 959px)': {
      width: '10px',
      height: '10px',
    },
  },
  hiddenNav: {
    marginTop: '21px',
    paddingLeft: '60px',
    paddingRight: '60px',
    paddingBottom: '20px',
    paddingTop: '20px',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginLeft: '-35px',
    '@media screen and (max-width: 1279px)': {
      marginTop: '17px',
      marginLeft: '-36px',
    },
    '@media screen and (max-width: 959px)': {
      marginTop: '14px',
      marginLeft: '-33px',
    },
  },
  hiddenNav2: {
    marginTop: '51px',
    marginLeft: '90px',
    paddingLeft: '60px',
    paddingRight: '60px',
    paddingBottom: '20px',
    paddingTop: '20px',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    '@media screen and (max-width: 1600px)': {
      marginTop: '46px',
      marginLeft: '68px',
    },
    '@media screen and (max-width: 959px)': {
      marginTop: '43px',
    },
  },
  mapNav: {
    gap: '6.4px',
  },
  alink: {
    fontFamily: 'Gotham',
    fontWeight: '400',
    color: 'black !important',
    lineHeight: '36px',
    fontSize: '20px',
    '&:hover': {
      color: '#ee1c25 !important',
    },
  },
  logo: {
    width: '150px',
    marginLeft: '280px',
    '@media screen and (max-width: 959px)': {
      width: '116px',
      marginLeft: '0px',
      position: 'absolute',
      top: '10px', // Ajusta la posición vertical del logo
      left: '50%',
      transform: 'translateX(-50%)', // Centra el logo horizontalmente
    },
  },
}));

const getBaseUrlFromHostname = () => {
  const { hostname } = window.location;
  if (hostname.includes('localhost')) return "http://localhost:3006";
  if (hostname.includes('wedoweb')) return "https://icentral.wedoweb.io";
  if (hostname.includes('iloanrate')) return "https://icentral.iloanrate.com";
  return "http://localhost:3006";
};

const links = [
  { name: 'Closing Board', customUrl: `${getBaseUrlFromHostname()}/closing-board/calendar` },
  { name: 'Sales Hub', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/sales-hub' },
  { name: 'Marketing Hub', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/marketing' },
  { name: 'Sign Out', customUrl: '/logout' },
];

const links2 = [
  { name: 'Admin Console', customUrl: `${getBaseUrlFromHostname()}/closing-board/calendar` },
  { name: 'Sign Out', customUrl: '/logout' },
  { name: 'Encompass:', customUrl: 'https://sites.google.com/iloanrate.com/icentral/home/marketing' },
];

const NavbarHome = () => {
  const classes = useStyles();
  const [isLeftOpen, setIsLeftOpen] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const userName = user.data.displayName;
  const { avatar } = user.data;

  const getInitials = userName =>
    userName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  const getAvatarImg = avatar => {
    return <img className="max-w-none w-auto h-full" src={avatar} alt="avatar" />;
  };
  const avatarContent = avatar ? getAvatarImg(avatar) : getInitials(userName);

  const toggleLeftDropdown = () => {
    setIsLeftOpen((prev) => !prev);
    setIsRightOpen(false); // Cierra el dropdown derecho
  };

  const toggleRightDropdown = () => {
    setIsRightOpen((prev) => !prev);
    setIsLeftOpen(false); // Cierra el dropdown izquierdo
  };

  return (
    <header className={clsx(classes.headernav, 'lg:pt-3 lg:pb-4 w-full')}>
      <div className="mx-20 md:mx-36">
        <div className="flex container items-center justify-between">
          {/* Dropdown izquierda */}
          <div>
            <div
              onClick={toggleLeftDropdown}
              className="cursor-pointer outline-none"
            >
              <img
                className={clsx(classes.loginIcons)}
                src="assets/images/icons/Hamburger_menu.svg"
                alt="Left menu"
              />
            </div>
            <div
              className={clsx(
                classes.hiddenNav,
                `bg-white fixed z-20 ${
                  isLeftOpen ? '' : 'hidden'
                } transition-all duration-500`
              )}
            >
              <div className={clsx(classes.mapNav, 'flex flex-col')}>
                {links.map((link, index) => (
                  <a
                    key={index}
                    className={clsx(classes.alink)}
                    target="_self"
                    href={link.customUrl}
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Logo central */}
          <div>
            <Link to="/menu/home-page">
              <img
                className={clsx(classes.logo)}
                src="assets/images/logos/icentral-logo.png"
                alt="logo"
              />
            </Link>
          </div>

          {/* Dropdown derecha */}
          <div className="flex gap-6 lg:gap-16">
            <div className="flex gap-10 items-center">
              <img
                className="w-20 lg:w-24"
                src="assets/images/icons/FAQ-icon.png"
                alt="FAQ"
              />
              <img
                className="w-20 lg:w-24"
                src="assets/images/icons/notification.png"
                alt="Notifications"
              />
            </div>
            <div
              onClick={toggleRightDropdown}
              className="flex gap-20 items-center cursor-pointer"
            >
              <Avatar className={clsx(classes.avatar, 'avatar')} alt="">
                {avatarContent}
              </Avatar>
              <div>
                <Typography className="username text-16 whitespace-no-wrap text-black font-800">
                  {user.data.displayName}
                </Typography>
                <Typography className="email text-13 whitespace-no-wrap text-black">
                  {user.data.email}
                </Typography>
              </div>
              <img
                className={clsx(classes.loginRow)}
                src="assets/images/icons/down-arrow.png"
                alt="down arrow"
              />
            </div>
            <div
              className={clsx(
                classes.hiddenNav2,
                `bg-white fixed z-20 ${
                  isRightOpen ? '' : 'hidden'
                } transition-all duration-500`
              )}
            >
              <div className={clsx(classes.mapNav, 'flex flex-col')}>
                <hr className="border-t border-black" />
                {links2.map((link, index) => (
                  <React.Fragment key={index}>
                    <a
                      className={clsx(classes.alink)}
                      target="_self"
                      href={link.customUrl}
                    >
                      {link.name}
                    </a>
                    {link.name === 'Sign Out' && (
                      <hr className="border-t border-black my-2" />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarHome;
